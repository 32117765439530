.rulesOfBehavior {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  height: 50%;
  position: relative;
}

.rulesOfBehavior .loginButtonArea {
  width: 90px;
  height: 37px;
  overflow: visible;
  background-color: rgba(1, 100, 185, 1);
  border-radius: 7px;
  margin-top: 100px;
  margin-bottom: 60px;
  align-items: center;
}

.parent { 
  display: flex; 
  flex-flow: row wrap;
  align-content: center; 
  justify-content: space-between;
  /* padding-top: 120px; */
  min-height: 100vh;
} 

.leftCol {
  width: 50%;
  height: 100%;
  flex: 1;
  align-content: center;
  align-items: stretch;
  
}

.rightCol {
  height: 50%;
  width: 50%;
  margin-bottom: 10px;
}


.child {
  display: flex; 
  /* grid-template-columns: repeat(2, 1fr);  */
  /* grid-column-gap: 10px;  */
  margin: 10px 0; 
  /* Testing using all flex */
  /* flex-direction: column; */
  /* flex-basis: 50%; */
  flex: 1 250px;
  /* flex-grow: 1; */
  box-sizing: border-box;
}

.button {
  align-self: center;
}

.line-break {
  white-space: pre-line;
}


.Background {
	background-color: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}



/* Old css below*/

.App {
  text-align: center;
  
  height: 100vh;
  position: absolute;
}

.mainWrapper{
  height: 100%;
  /* height:100vh; */
  /* min-height: calc(100vh-150px); */
}


.routingArea{
  /* margin-left: 20px;
  margin-right: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
}
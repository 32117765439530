.robSection {
  margin-top: 20px;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  align-items: left;
}

.usaid-blue-redesign {
  background-color: #0164B9;
  width: 175px;
}

.contentSection {
  border: 1px solid lightgrey; 
  /* border-radius: 10px; */
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  /* stroke: rgba(112, 112, 112, 1);
   */
   stroke: lightgray;
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  border-radius: 0.3rem !important;
  height: inherit;
  margin-inline:auto;
}

.contentSection-redesign {
  border: 1px solid gray; 
  padding: 10px;
  background-color: #f8f8f8;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  border-radius: 0.3rem !important;
  height: inherit;
  margin-inline:auto;
}

.contentSection-help {
  border: 1px solid gray; 
  padding: 10px;
  background-color: #f8f8f8;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  height: inherit;
  margin-inline:auto;
}

.textFormat {
  line-height: 20px;
  font-family: sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}

.contentSection .agree-checkbox-area {
  overflow: visible;
  white-space: nowrap;
  line-height: 18px;
  margin-top: -2px;
  text-align: left;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}
.contentSection .agree-checkbox {
  width: 24px;
  height: 24px;
  overflow: visible;
  padding-left: 0.4em !important;
}

.contentSection .agree-checkbox-area .agree-checkbox-text {
  font-family: sans-serif;
  vertical-align: super;
  padding-left: 0.4em;
}

.loginButtonArea {
  width: 90px;
  height: 37px;
  overflow: visible;
  background-color: rgba(1, 100, 185, 1);
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;
}

.loginButton {
  color: rgba(255, 255, 255, 1);
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  background-color: rgba(1, 100, 185, 1);
  align-items: center;
  align-content: center;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  /* container-type: inline-size; */
}

.ag-theme-quartz {
  --ag-header-background-color: #0164B9;
  --ag-header-foreground-color: white;
}

@container (min-width: 479px) {
  .container {
    max-width:479px;
    flex-direction: column;
  }
  body {
    font-size: 0.15em; 
  }
}

@container (min-width:768px) {
  .container {
    max-width:768px;
  }
  body {
    font-size: 0.75em;
  }
}

@container (min-width:992px) {
  .container {
    max-width:992px;
  }
  body {
    font-size: 0.75em; 
  }
}

@container (min-width:1200px) {
  .container {
    min-width:100vw;
  }
  body {
    font-size: 1em; 
  }
}
/* Styles for ReVetSubmission */

/* Use for testing/troubleshooting */
/* * {
  outline: 1 solid green;
} */

.revet-wrapper {
    display: grid;
    grid-gap: 2em;
    background: #fff;
    color: #000;
    line-height: 18px;
    margin-left: 6rem;
    margin-right: 6rem;
    width: 100%;
    min-height: 100%vh;
    justify-content: flex-start;
    grid-template-columns: 1fr;
    /* Use if they decide it needs to be centered instead */
    /* justify-content: center; */
    border: 1 solid lightgrey;
    cursor: pointer;
    position: relative;
}

.revet-background {
  background-color: #f8f8f8;
  border-radius: 8px;
  border: #c4c2c2;
  border-style:solid;
}

.revet-heading {
  font-weight: bold;
  color:black;
}

/* * {
  padding:0;
  margin:0;
} */

.navContainer {
  /* position: relative; */
  display: flex;
  justify-content: start;
  top: 1em;
  margin-left:1em;
  left:0;
  width: 30em;
  /* z-index: 1; */
}

.revet-text {
  font-size: 18px;
  color: black;
  margin-left: 10px;
}

.revet-btn {
  /* font-size: 1.8em; */
  background-color: #0164B9;
  color:white;
  /* padding: 0.5em 2.5em; */
  padding: .5em ;
  /* width: calc(35% - 1em); */
  min-width: 200px;
  margin-bottom: 2em;
  margin-right: 2em;
}

.container {
  min-height: 100vh;
}

ol > li {
  font-size: 18px;
  color: black;
  margin-left: 30px;

}


.breadcrumb {

    --bs-breadcrumb-divider: ' | ';
    display: var (showDisplay);
}



.content {
    padding:0;
    margin: 0;
    /* min-width: 200px; */
    /* hides button functionality */
    /* height: 100vh; */
    /* position: fixed; */
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    box-sizing:border-box;
    overflow:hidden;
    font-weight: bold;
    left:0px;
    /* background-color: blueviolet; */
}

.Nav {
    max-width: 100vw;
    display: flex;
}

/* div .navContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-content: flex-start;
} */

body {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
    height: 100dvh;
}

.navigation {
    /* width:100%; */
    box-sizing: border-box;
    left:1em;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "|" !important;
    padding-right:10px !important;
  }

.no-breadcrumb.breadcrumb-item::before {
    content: " ";
  }

  .no-underline {
    text-decoration: none;
  }

  .disabled-link {
    pointer-events:none;
  }

@media screen and (max-width: 576px) {
    .navigation {
     width: 100%;
     min-height:100vdh;
     box-sizing:border-box;
     display: block;
     font-weight: bolder;
     left:2em;
      /* z-index: 1030; */
    }
  }
  
/* Styles for Radio Buttons with Icons */

.subAwardWrapper {
    display: stretch;
    /* flex-wrap: wrap; */
    background: #fff;
    margin-inline: auto;
    width: 94%;
    /* width: min(100%,75rem);  */
    /* align-content: center; */
    justify-content: center;
    /* border-radius: 5px; */
    border: 0 solid lightgrey;
    cursor: pointer;
    position: relative;
    min-height: 100vh;
}

.heading {
  margin-top: 75px;
}


.navContainer {
  /* position: relative; */
  display: flex;
  justify-content: start;
  top: 1em;
  margin-left:1em;
  left:0;
  width: 30em;
  /* z-index: 1; */
}


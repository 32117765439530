.flex-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  body {
    line-height: 18px;
    font-family: sans-serif;
    font-size: 16px;
    color:black;
  }
/* input #filter-text-box::placeholder {
    color: lightgray;
    opacity: 1;
} */

calendar_icon{
    height: 30px;
}

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }
  
  .table-box {
    padding: 10px;
    
    @include for-tablet-portrait-up {
      padding: 5px;
    }
  }

  .usaid-blue {
    background-color: #0164B9;
  }

  .textFormat {
    line-height: 18px;
    font-family: sans-serif;
    font-size: 1em;
    color: rgba(0, 0, 0, 1);
  }
  
.ag-row:nth-child(odd) {
    background-color:rgba(248,248,248,1);
}
.ag-row:nth-child(even) {
    background-color: #ffffff;
}

/* flex container settings */
.flex-container {
  display: flex;
  justify-content: flex-start;
  gap:0;
  /* align-items: baseline; */
}

.ag-header-cell-label {
  justify-content: center;
  font-weight: bolder;
}

.pseudoLink {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.ag-theme-quartz {
  --ag-header-background-color: #0164B9;
  --ag-header-foreground-color: white;
}
.card {
    width: 15rem;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.award a {
    text-decoration: none;
}

.the-line {
  height: 45px;
  width: 10%;
  border-bottom:12px solid #bbb;
}

.grid-container {
  /* grid-template-columns: repeat(4, fr); */
  display: flex;
  grid-auto-flow: column;
  grid-auto-columns: 100px;
  justify-items: center;
}

@media screen and (max-width: 600px) {
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .the-line {
    display: none;
  }
  .individual-line2 {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .the-line {
    display: none;
  }
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.usaid-blue {
  background-color: #0164B9;
}

.individual-line2 {
  height: 45px;
  width: 10%;
  border-bottom:12px solid #bbb;
}

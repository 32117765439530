/* .footer{
   background-color: gray;
   margin-top: 50px;
   height: 20%;
   
} */

.footerlinks {
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  text-align: end;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);  
  
}

.footerlinks a {
  text-decoration: none;
  color: black;
  font-size: medium;
  font-weight: bold;
  padding-left: 0.6em;
  padding-right: 0.6em;
}

/* Newer css */

#footer {
  /* align-items: right; */
  background-color: rgba(222, 217, 217, 1);
  position: fixed;
  bottom: 0;
  left:0;
  margin-right: auto;
  width:100%;
  margin-top: 10px;
  padding-top: 5px;


  /* margin-top: -1.5rem;
  height: 1.5rem;
  clear:both;
  margin-top:auto; */
  /* position: fixed; */
  /* left: 0; */
  /* bottom: 0; */
  /* right: 0; */
}

html {
  height: 100%;
  box-sizing: border-box;

}

body {
  min-height: 100%;
  box-sizing: inherit;
}

@media (max-width: 600px) {
  .footer {
    width: 100%;
    height: 100%;
    /* margin-bottom: 20px; */
    position: relative;
    left: auto;
    height: auto;   
    margin-top:auto;
  }
}

/* @media (max-width: 400px) {
  .footer {
    position: relative;
    bottom: auto;
    left: auto;
    height: auto;
  }
} */
.left {
    text-align:'left' !important;
    /* background-color: brown; */
}


.titleSection {
    text-align: left;
}

.pif-id-review {
  margin-top: 20px;
  font-size: 16px;
  padding: 15px;
  box-shadow: rgba(0,0,0,.2) 0 2px 10px !important;
  border-radius: 1.1428571429rem !important;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  box-sizing: border-box;
  border: 1px solid lightgrey; 
}

.container {
    position: relative;
    /* font-size: 1em; */
    /* width:100%; */
    margin-left: auto;
    margin-right: auto;
    /* padding-top: 140px; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-inline: auto;
    line-height: 18px;
    font-family: sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

body {
    height: 100%;
    padding: 0;
    margin: 0;
}

.contentSection-review {
    border: 1px solid gray; 
    border-radius: 10px;
    padding: 10px;
    background-color: #f8f8f8;
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    border-radius: 0.3rem !important;
    height: inherit;
    margin-inline:auto;
  }
  
  .textFormat {
    line-height: 18px;
    font-family: sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
  }

  .contentSection .agree-checkbox-area {
    overflow: visible;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -2px;
    text-align: left;
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
  }
  .contentSection .agree-checkbox {
    width: 24px;
    height: 24px;
    overflow: visible;
    padding-left: 0.4em !important;
  }
  
  .contentSection .agree-checkbox-area .agree-checkbox-text {
    font-family: sans-serif;
    vertical-align: super;
    padding-left: 0.4em;
  }

  .usaid-blue-review {
    background-color: #0164B9;
    width: 175px;
  }

  .reviewSection {
    margin-top: 20px;
    margin-bottom: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    align-items: left;
  }

  dt {
    display: block;
    float: left;
    width: 150px;
    text-align: right;
  }
  
  dt:after {
    content: ':';
    margin-right: 5px;
  }
  
  dd {
    display: block;
  }


/* @media (
min-width: 576px) {
    .container {
        max-width:475px;
    }
} */
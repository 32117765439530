.control-div {
  margin-left: 1em;
  margin-right: 1em;
}

.label-div {
  margin-left: 1em;
  margin-top: 1em;
  width: auto;
  box-sizing: inherit;
  /* font-size: clamp(0.5em, 0.75em, 1em); */
}

.label-div-required {
  margin-left: 1em;
  margin-top: 1em;
  color: red;
}

.orgbutton {
  margin: 2em;
}

/* .btn {
  background-color: #0164B9;
  color:white;
} */

fieldset {
  border: 1px solid lightgrey;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  /* container-type: inline-size; */
  font-size: clamp(0.75em, 1em, 1em);
  margin-inline:auto;
  box-sizing: border-box;
}

body {
  /* font-size: clamp(0.50em, 0.75em, 1em);  */
  box-sizing: border-box;
  /* line-height: 18px; */
  font-family: sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}

.orgTitleSection {
  margin-top: 20px;
  margin-bottom: 6px;
  font-style:normal;
  font-size: 16px;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  align-items: left;
}

input[type="radio"] {
  margin:10px 5px;
  display: inline;
  height: 15px;
  width: 15px;
  vertical-align: -2px;
}

.usaid-blue-org {
  background-color: #0164B9;
  width: 175px;
}

#triangle-down {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid black;
}

.file-upload {
  border:none;
  color:none;
}


.dropdown-toggle::before {
  display:none;
}

.dropdown-toggle::after {
   border-top: transparent;
   content: url('../../../assets/images/svg/chevron-down.svg');
   
}
/* 
.css-16d15bc-MuiInputBase-root-MuiInput-root::before {
  border:"none";
} */

.MuiAutocomplete-root div:first-of-type:hover{
  border-color: #2196F3;
}

.asterisk::before {
  color:red;
  content:'*';
}


@media (max-width: 475px) {
  .container {
    width:100%
  }
 
}

@media (max-width: 640px) {
  .container {
    max-width:475px;
  }
 
}

@media (max-width:768px) {
  .container {
    max-width:640px;
  }
}

@media (max-width:1024px) {
  .container {
    max-width:768px;
  }
}

@media (max-width:1280px) {
  .container {
    max-width: 1024pxpx;
  }
}

@media (max-width:1536px) {
  .container {
    max-width: 1280px;
  }
}

.left {
  position: relative;
  left: -13px
}

.left-start {
  position: relative;
  left: -1em;
}

.pif-id-org {
  margin-top: 20px;
  font-size: 16px;
  padding: 15px;
  box-shadow: rgba(0,0,0,.2) 0 2px 10px !important;
  border-radius: 1.1428571429rem !important;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  box-sizing: border-box;
  border: 1px solid lightgrey; 
}

.orgContentSection {
  border: 1px solid lightgrey; 
  /* border-radius: 10px; */
  padding: 10px;
  background-color: #f8f8f8;
  /* stroke: rgba(112, 112, 112, 1);
   */
   stroke: lightgray;
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px !important;
  border-radius: 1.1428571429rem !important;
  height: inherit;
  margin-inline:auto;
}

/* Not using parent currently */
.parent {
  display: flex;
}

.parent > * {
  flex-basis: 100%;
}

.orgz-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-auto-columns: 4fr; */
  /* grid-auto-columns: minmax(0, 1fr); */
  grid-template-rows: 1fr min-content;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 4em;
  font-size: 16px;
  color: black;
  margin: 0 1rem;
}

.orgz-grid-container {
  display: grid;
  /* grid-auto-columns: minmax(0, 1fr); */
  /* grid-auto-columns: 1fr; */
  grid-template-columns: repeat(autofit, minmax(min(100%, 1fr)));
  grid-auto-flow: column;
}

.item-col1 {    
    text-align: start;
    grid-column: 1 / span 2;
}

.item-col3 {
  grid-column: 3 / span 2;
}

.item-span4 {
  grid-column: 1 / span 4;
  text-align: start;
}

.btn-grid {
  grid-column: 1 / 5;
  text-align: center;
  column-gap: 20px;
}

.ag-theme-quartz {
  --ag-header-background-color: #0164B9;
  --ag-header-foreground-color: white;
}

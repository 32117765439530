/**
*  AboutComponent.css
* 
*/

.line-break {
  white-space: pre-line;
}

.shaded-content-section {
  /* background-color: #f8f8f8; */
  background-color: rgba(255, 255, 255, 1);
}

.test-color {
  background-color: green;
}
.contactTitleSection {
    margin-top: 25px;
    margin-bottom: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
    align-content: center;
  }

  .contentSection-contact {
    border: 1px solid gray; 
    border-radius: 10px;
    padding: 10px;
    background-color: #f8f8f8;
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px !important;
    border-radius: 1.1428571429rem !important;
    height: inherit;
    width: 90%;
  }

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    max-width: 800px;
  }
  
.row {
    align-content: center;
  }

  .normalText {
    font-style: normal;
  }

  .heavierText {
    font-weight: bold;
    text-align: center;
  }

  .contact-table {
    border-collapse: collapse;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
  }
 
  .contact-th {
    border: 1px solid #dddddd;
    background-color: #0164B9;
    color: #ffff;
    padding-left: 10px;
    font-size: 18px;
    font-family: sans-serif;
    padding-bottom: 10px;
    vertical-align: middle;
  }

  .contact-td {
    padding-left: 10px;
    font-size: 18px;
    font-family: sans-serif;
    padding: 10px;
  }
  
  .contact-tr:nth-child(odd) {
    background-color: rgba(248,248,248,1);
  }

  .contact-tr:nth-child(even) {
    background-color: white;
  }
::placeholder {
    color:black !important;
  }

  ::-ms-input-placeholder { /* Edge 12-18 */
    color: black !important;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: black !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: black !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: black !important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: black !important;
  }

  .btn {
    background-color: #0164B9;
    color:white;
  }

  .btn:hover, .btn:focus, .btn::selection {
    /* background-color: #0164B9; */
    background-color: gray;
    /* color:white; */
    color:black;
    font-weight: bold;
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    /* transform: translateY(-0.25em); */
}

/* Change color of Orgz & Indiv icons on hover */
.fa-user:hover, .fa-user:focus, .fa-user::selection{
  color:black;
}

.fa-landmark:hover, .fa-user:focus, .fa-user::selection {
  color:black;
}

.icon-white {
  color: white;
}



.dropdown-toggle::before {
  display:none;
}

.dropdown-toggle::after {
   border-top: transparent;
   content: url('../../../assets/images/svg/chevron-down.svg');
   
}

.error {
  color: red;
  margin-left: 15px;
}

.pif-id-info {
  font-size: 16px;
  padding: 15px;
  box-shadow: rgba(0,0,0,.2) 0 2px 10px !important;
  border-radius: 1.1428571429rem !important;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  box-sizing: border-box;
  border: 1px solid lightgrey; 
}

.awardContentSection {
  border: 1px solid lightgrey; 
  /* border-radius: 10px; */
  padding: 10px;
  background-color: #f8f8f8;
  /* stroke: rgba(112, 112, 112, 1);
   */
   stroke: lightgray;
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px !important;
  border-radius: 1.1428571429rem !important;
  height: inherit;
  margin-inline:auto;
}

.wrapper {
  align-items: center
}
/* 
.wrapper div {
  height: 100px;
} */

.biggerDiv {
  padding-top: 30px;
  padding-bottom: 30px;
  max-height: 150px;
  height: 1000px;
}

.regularDiv {
  padding: auto;
}

.select-card {
  width: 18rem;
  height: 5rem;
  background-color: white;
}

.usaid-blue-info {
  background-color: #0164B9;
  width: 175px;
}

.header{
    background-color: rgba(0,47,108,1);
    top:0px;
    width:100%;
    margin-left: auto;
    margin-right: auto;
	box-shadow: rgba(0.2,0.2,0,.2) 0 2px 10px !important;
    position: sticky;
    z-index: 1000;
}

.headerlinks {
    display: flex;
    white-space: nowrap;
    /* text-align: right; */
    text-align: bottom;
    padding-top: 105px;
    margin-right: -50px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);  
    
  }

  .menulinks a {
  text-decoration: none;
  color: white;
  text-align: right;
  font-size: medium;
 
}


.PVSportalLogo {
	position: relative;
    align-items: center;
    padding-left: 200px;
    padding-top: 21px;
    height: 77px;
    width: 182px;

}
.USAID_Logo {
    /* position: absolute; */
    position: relative;
	width: 277px;
	height: 83px;
	padding-left: 18px;
	padding-top: 19px;
	overflow: visible;
} 
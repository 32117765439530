/* Styles for Radio Buttons with Icons */

.wrapper {
    display: flex;
    background: #fff;
    height: 100%;
    width: 100%; 
    align-content: center;
    justify-content: space-evenly;
    border-radius: 5px;
    border: 0 solid lightgrey;
    padding: 20px 10px;
    cursor: pointer;
    position: relative;
    min-height: 100vh;
    margin-top: 10em;
    
} 

.btn {
  background-color: lightgray;
}

.btnSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 20px;
}

.btn:active { 
  transform: scale(0.98); 
  -webkit-transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */ 
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
  /* Lowering the shadow */ 
} 


.heading {
    margin-top: 55px;
}

.text {
    font-family: sans-serif;
    font-size: 1em;
    font-style: normal;
    line-height: 18;
}

/* Testing - putting all components from SubAward here */

[type=radio] { 
    /* position: absolute; */
    width:20px;
    height:20px;
    display: inline-block;
    padding-bottom: 0;
  } 

  label {
    /* padding: 0; */
    /* padding: 10px; */
    /* display: inline-block; */
    font-size: 1em;
    border-radius: 5px;
  }
  
  label:focus-within {
    /* background-color: blue; */
    /* background-color: #4b82e7 */
    /* background-color: #0164B9; */
    color: white;
    transition: all 3s;
  }
 
 .radioSection {
    display: flex;
    width:60px;
    height:60px;
    margin-right: 20px;
  }

  .asterisk:before {
    content:" *";
    color: red;
    margin-right: 5px;
  }

  .usaid-blue {
    background-color: #0164B9;
  }

  .btn-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    vertical-align: middle;
  }

  

#status_select{
    width:100%;
}

#pif-filter{

    width: 100%;
    background: rgba(248,248,248,1);
    /*border-radius: 25px;*/
    margin-top: 3%;
}


#main-filter{
    width: 60%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.calendar_icon{
    height: 30px;
}

#date_picker_area{
    width: 360px;
}

[role=tooltip].popup-content {
    width: 362px;
}

.rs-picker-toggle-read-only{
    opacity: 100;
}

#table-filter{
    width: 98%;
}


.message_icon {
    height: 21px;
}

.pdf_icon{
    height: 21px;
}
.delete_icon{
    height: 21px;
}

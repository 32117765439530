.login {
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px;
    background-color: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    box-sizing: border-box; 
    margin-top: 20px;
}

body {
    height: calc(100vh - 150px)
}

html {
    min-height: 100%;
}
.navContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* margin-inline: auto; */
  }

 .dash-background {
  border: 1px solid gray; 
  border-radius: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  border-radius: 0.3rem !important;
  height: inherit;
  margin-inline:auto;
 }

 .dash-title {
  margin-left: 5px;
  color: black;
 }

  .btn-style {
    background-color: #0164B9;
    color:white;
    padding: .5em ;
    width: calc(100% - 1em);
    max-width: 150px;
    margin-bottom: 2em;
    margin-right: 2em;
  }

  .dashboard-grid-container {
    display: grid;
    justify-content: last baseline;
    grid-template-columns: repeat(auto-fit,minmax(200px, 4fr));
    
    column-gap: 5em;
  }

  /* grid-template-columns: repeat(200px, 1fr); */

  .grid-container > div {
      font-size: 18px;
      padding: 0.5rem 1rem;
      text-align: center;
  }

   
.text-link {
    color: inherit;
    text-decoration: none;
}


.right-side, .left-side {
  list-style-type: none;
}

.btn {
  display: flex;
  justify-content: center;
}